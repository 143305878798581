<template>
  <view-container v-if="competition" name="details">
    <view-header>
      <custom-header class="view-header" :router-back="$goBack">
        <template v-slot:default>
          <svg-icon size="size24x24" name="icoArrowLeft"/>
          {{ competition.name }}
        </template>
      </custom-header>
    </view-header>

    <view-content>        
        <template v-if="competition.events">
          <router-link v-for="event in competition.events" :key="event.id" class="tournament-link" :to="{ name: 'streamed-tournament-event', params: { id: event.id } }" :disabled="event.status.toLowerCase() === 'upcoming'">
            <span class="tournament-link__name"> {{ event.name }} </span>
            <div class="d-flex align-items-center">
                <span v-if="event.status === 'Streamed'" class="tournament-link__status streamed">
                  <Icon :size="14" type="check" style="fill: #51DF3A" class="me-1"/>
                  {{ event.status }}
                </span>
                <span v-else class="tournament-link__status upcoming">
                  {{ event.status }}
                </span>
                <Icon :size="26" class="tournament-link__icon" type="caret-right"/>
            </div>
        </router-link>
        </template>
    </view-content>

  </view-container>
</template>

<script>
import competition from '@/mixins/competition'
import Icon from '@/components/Icon'
import { getTournamentsVerdict } from '@/api/public/competition'

export default {
  name: 'StreamedTournament',
  mixins: [
    competition
  ],
  props: {
    competitionId: {
      type: [String, Number],
      required: true
    }
  },
  components: {
      Icon,
  },
   data () {
    return {
      competition: null
    }
  },
  created () {
    this.initCompetition()
  },
  methods: {
    async initCompetition () {
      try {
      const { data: competition } = await getTournamentsVerdict(this.competitionId)

      this.competition = competition
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>

<style scoped>
 .organizer{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    margin: 0 0 16px 0;
    color: #fff;
    border-radius: 8px;
 }
 .details{
    font-size: 12px;
    color: #7B8B97;
}
 .details .numbers{
    color: #fff;
}
.date{
    gap: 10px;
}
.tournament-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
}
.tournament-link__name{
    color: #fff;
    font-size: 16px;
    line-height: 22px;
}
.tournament-link__icon{
    margin-left: 20px;
    fill: #fff;
}
.streamed{
  color: #51DF3A; 
  font-size: 10px;
}
.upcoming{
  color: #FFA843; 
  font-size: 10px;
}
</style>